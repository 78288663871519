@import "../node_modules/bootstrap/scss/bootstrap";

body {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    .percentage {
        position: absolute;
        height: 100vh;
        background: #dff1fd;
        transition: all 1s ease-in-out;
        &.dogs {
            right: 0;
            background: #acbff5;
        }
    }
}

.result {
    font-size: 2rem;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-width: 400px;
    > div {
        width: 50%;
    }
}

.counter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    min-height: 100px;

    &.text-danger {
        font-size: 3rem;
    }
}